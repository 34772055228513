import {CrownBox} from "../../model/crownBox";
import {Box, BoxProps, HStack, Image} from "@chakra-ui/react";
import {CrownText} from "../common/CrownText";

type Props = {
  box: CrownBox
  heightPercent: string
}

export const BoxThumbnail = (props: Props & BoxProps) => {
  const { box, heightPercent, ...boxProps } = props;
  return (
    <Box position={'relative'} pt={heightPercent} h={0} w={'full'} rounded={'lg'} {...boxProps}>
      <Image src={box.thumbnail} inset={0} position={'absolute'} objectFit={'cover'} h={'full'} w={'full'} rounded={'lg'} />
      <HStack position={'absolute'} left={0} bottom={12}>
        {box.keywords.map((keyword) => (
          <HStack key={keyword} px={2} h={6} bg={'#e2943b'} color={"white"}>
            <CrownText bold small>{keyword}</CrownText>
          </HStack>
        ))}
      </HStack>
      <HStack position={'absolute'} left={0} bottom={4} px={2} h={6} bg={'white'}>
        <CrownText yellow bold small>{box.name}</CrownText>
      </HStack>
    </Box>
  )
}
