import {Box, BoxProps} from "@chakra-ui/react";

const backgroundProps = {
  backgroundImage: 'linear-gradient(0deg, transparent 5px, #EBECEB 5px),linear-gradient(90deg, #FFFFFF 5px, #EBECEB 5px)',
  backgroundSize: '5.5px 5.5px'
}

export const MeshBox = ({children, ...props}: BoxProps) => {
  return <Box {...backgroundProps} {...props}>{children}</Box>
}
