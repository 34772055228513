import React from "react";
import { ShippingPlan } from "../../../model/shippingPlan";
import { BoxType } from "../../../model/crownBox";

import * as Imgs from './product_example_banner/imgs'
import { Banner } from "../../common/Banner";

type BoxTypeInfo = {
  type: BoxType
  url: string
  banner: string
}[]

const type_url: BoxTypeInfo = [
  {
    type: 'A',
    url: 'https://palett.notion.site/36f5e84f249743ed80e698011895d6e2',
    banner: Imgs.A.src
  },
  {
    type: 'B',
    url: 'https://palett.notion.site/472568eb5ec842fda8895e834c0e3548',
    banner: Imgs.B.src
  },
  {
    type: 'C',
    url: 'https://palett.notion.site/4def43721f174536849adbcd00729f61',
    banner: Imgs.C.src
  },
  {
    type: 'E',
    url: 'https://palett.notion.site/ea9e7fe939ac4a1aa3137f61dda7fb36',
    banner: Imgs.E.src
  },
]

type Props = {
  shippingPlans: ShippingPlan[]
}

const arrivedBox = (shippingPlans: ShippingPlan[]):ShippingPlan[] => {
  const currentDate = Date.now()
  return shippingPlans.filter(({shippedAt})=> {
    const shippedDate = new Date(shippedAt)

    const arriveDate = shippedDate.setDate(shippedDate.getDate() + 2)
    return arriveDate <= currentDate
  })
}

export const ProductExample = (props: Props) => {
  const { shippingPlans } = props;
  if (shippingPlans.length < 1) return <></>
  const box_type = arrivedBox(shippingPlans).slice(-1)[0]?.crownBox?.boxType
  const box_info = type_url.find(({type})=> type == box_type)
  return (
    <>
      { box_info &&
        <Banner href={box_info.url} img={box_info.banner} isExternal={true} />
      }
    </>
  )
}