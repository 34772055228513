import {Box, HStack} from "@chakra-ui/react";
import {CrownText} from "../../common/CrownText";
import {BoxThumbnail} from "../../crownBox/BoxThumbnail";
import {MeshBox} from "../../common/MeshBox";
import {Icon} from "../../icons";
import {formatYYYYMMDD} from "../../../lib/timeFormat";
import {ShippingPlan} from "../../../model/shippingPlan";
import {Personalizing} from "./Personalizing";

type Props = {
  shippingPlan: ShippingPlan;
}

export const PlanSummaryItem = ({ shippingPlan }: Props) => {
  return (
    <Box
      w={'75vw'}
      maxW={'calc(425px * 0.75)'}
      flexShrink={0}
      style={{
        transformStyle: 'preserve-3d',
        scrollSnapAlign: 'center',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mb={3}
        style={{
          transform: 'translateZ(-136.8px) scale(2.638)',
          transformOrigin: 'top'
        }}
      >
        <CrownText min>{shippingPlan.time}回目のボックス</CrownText>
        <Box rounded={'full'} w={1} h={1} mt={'6px'} bg={'rgb(46 58 45)'}/>
      </Box>
      {shippingPlan.crownBox ?
        <BoxThumbnail box={shippingPlan.crownBox} heightPercent={'70%'} /> :
        <Personalizing heightPercent={'70%'} />
      }
      <MeshBox mt={2} rounded={'lg'} h={'44px'} as={HStack} justifyContent={'center'} gap={4}>
        <HStack>
          <Icon icon={'calender'}/>
          <CrownText small bold>
            {shippingPlan.shippingStatus === 'fixed' ? '発送日' : '発送予定日'}
          </CrownText>
        </HStack>
        <HStack>
          <CrownText small>{formatYYYYMMDD(new Date(shippingPlan.shippedAt))}</CrownText>
        </HStack>
      </MeshBox>
    </Box>
  )
}
