import { useDisclosure } from "@chakra-ui/hooks"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Img,
  Center,
  Text, Checkbox
} from "@chakra-ui/react"
import IconImage from './icon.png'
import MeritImage from './merit.png'
import BannerImage from "./banner.png"
import React, { memo, useEffect, useRef, useState } from "react"
import { Banner } from "../common/Banner";

export const LineModal = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [checked, setChecked] = useState(false)

  const isFirstRender = useRef(false)

  const localKeyName = 'not_display_line_modal'

  useEffect(() => {
    isFirstRender.current = true
    const display = localStorage.getItem(localKeyName)
    if (!display){
      onOpen()
    }
  },[])

  useEffect(()=>{
    if(isFirstRender.current) {
      isFirstRender.current = false
    } else {
      if (checked) {
        localStorage.setItem(localKeyName, 'true')
      } else {
        localStorage.removeItem(localKeyName)
      }
    }
  },[checked])

  const onChangeDisplay = () => {
    setChecked(!checked)
  }

  
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={'sm'} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent bgColor={'#ECEBE6'}>
          <ModalCloseButton />
          <ModalHeader>
            <Center flexDirection={"column"} display={"flex"} alignItems={"center"}>
              <Img src={IconImage.src} w={'25%'}/>
              <Text bgColor={"white"} px={1} my={4}>LINE連携でもっと便利に</Text>
            </Center>
          </ModalHeader>
          <ModalBody>
            <Img src={MeritImage.src} />
          </ModalBody>
          <ModalFooter>
            <Banner href={'/line/login'} img={BannerImage.src} />
          </ModalFooter>
          <Center mb={4}>
            <Checkbox iconSize={6} isChecked={checked} onChange={onChangeDisplay}>
              <Text fontSize={9} color={"gray"}>
                次回以降表示しない
              </Text>
            </Checkbox>
          </Center>
        </ModalContent>
      </Modal>
    </>
  )
})