import {Box, BoxProps, Divider, HStack, Image, Link, Spacer, VStack} from "@chakra-ui/react"
import {ChildWithType} from "../../model/child";
import {CrownText} from "../common/CrownText";
import {Icon} from "../icons";
import NextLink from "next/link";

type Props = {
  child?: ChildWithType
}

const backgroundProps = {
  backgroundImage: 'linear-gradient(0deg, transparent 5px, #EBECEB 5px),linear-gradient(90deg, #FFFFFF 5px, #EBECEB 5px)',
  backgroundSize: '5.5px 5.5px'
}

export const ChildTypeSummary = ({child, ...boxProps}: Props & BoxProps) => {
  return (
    <Box {...backgroundProps} rounded={'lg'} {...boxProps}>
      <Box w={'full'} pt={'42%'} display={'flex'} flexDirection={'column'}>
        <Box mt={'-42%'} flex={1}>
          <HStack py={4} px={2}>
            <Box flex={1}>
              <Image src={child?.mi.imageUrl}/>
            </Box>
            <VStack flex={1} letterSpacing={'1.5px'}>
              <CrownText yellow micro bold>{child?.richName}は</CrownText>
              <CrownText mid>
                <CrownText as={'span'} bold>{child?.tci.title}</CrownText>
                {child?.tci.conjunction}
              </CrownText>
              <CrownText mid>
                <CrownText as={'span'} bold>{child?.mi.title}</CrownText>
                タイプ
              </CrownText>
              <Spacer />
              <HStack>
                <CrownText yellow micro bold>#{child?.tci.tag}</CrownText>
                <CrownText yellow micro bold>#{child?.mi.tag}</CrownText>
              </HStack>
            </VStack>
          </HStack>
          <Divider />
          <HStack justifyContent={'end'} px={4} py={2.5}>
            <NextLink href={`/children/${child?.id}/type_explain`}>
              <Link>
                <HStack>
                  <CrownText min gray bold>詳細をみる</CrownText>
                  <Icon icon={'arrowRightG'} h={'10px'} />
                </HStack>
              </Link>
            </NextLink>
          </HStack>
        </Box>
      </Box>
    </Box>
  )
}
