import type { NextPage } from 'next'
import {MyPageTop} from "../components/pages/MyPageTop";

const Home: NextPage = () => {
  return (
    <MyPageTop />
  )
}

export default Home
