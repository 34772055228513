import { Box, Img, Link } from "@chakra-ui/react";
import NextLink from "next/link";
import React from "react";

type Props = {
  href: string,
  img: string
  isExternal?: boolean
}

export const Banner = (props: Props) => {
  const { href, img, isExternal=false } = props;
  return (
    <NextLink href={href} passHref>
      <Link isExternal={isExternal}>
        <Box m={4}>
          <Img src={img}/>
        </Box>
      </Link>
    </NextLink>
  )
}