import {Box, Center, HStack, Image, VStack} from "@chakra-ui/react";
import bg from './personalising-bg.png';
import image0 from './personalising-0.png';
import image1 from './personalising-1.png';
import image2 from './personalising-2.png';
import {CrownText} from "../../common/CrownText";

type Props = {
  heightPercent: string
}

export const Personalizing = ({ heightPercent }: Props) => {
  return (
    <Box position={'relative'} pt={heightPercent} h={0} w={'full'} rounded={'lg'}>
      <Image src={bg.src} inset={0} position={'absolute'} objectFit={'cover'} h={'full'} w={'full'} rounded={'lg'} />
      <VStack position={'absolute'} inset={0} spacing={3} justify={'center'}>
        <HStack spacing={'30px'}>
          <Image src={image0.src} w={'60px'} h={'auto'} />
          <Image src={image1.src} w={'60px'} h={'auto'} />
          <Image src={image2.src} w={'60px'} h={'auto'} />
        </HStack>
        <Center bg={'#e2943b'} h={'28px'} px={'6px'}>
          <CrownText mid bold white>只今パーソナライズ中です</CrownText>
        </Center>
        <Center bg={'white'} h={5} px={'6px'}>
          <CrownText small yellow>お届け予定日の1ヶ月前に</CrownText>
        </Center>
        <Center bg={'white'} h={5} px={'6px'}>
          <CrownText small yellow>ボックスの詳細が決定します</CrownText>
        </Center>
      </VStack>
    </Box>
  )
}