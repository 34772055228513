import {Child, ChildWithType} from "../../model/child";
import React, {ReactNode} from "react";
import {
  HStack,
  Text,
  VStack
} from "@chakra-ui/react";
import {Icon} from "../icons";
import {CrownText} from "../common/CrownText";
import {Color} from "../../lib/color";

type DefaultProps = {
  mode: 'default';
  child: Child;
}

type DetailProps = {
  mode: 'detail';
  child: ChildWithType;
}

type Props = (DefaultProps | DetailProps) & {
  action?: ReactNode;
  bgColor?: string;
}

export const ChildSummary: React.FC<Props> = ({ child, mode, action, bgColor }) => {
  return (
    <HStack bgColor={bgColor ? bgColor : 'brand.100'} w={'full'} h={68} px={4} py={2.5} rounded={'md'}>
      <Icon icon={child.gender} w={12} h={12} p={2} rounded={'full'} backgroundColor={'white'} />
      {mode === 'default' ? (
        <VStack flex={1} align={'start'} spacing={1}>
          <Text fontSize={14} fontWeight={"bold"}>{child.name}{child.gender == 'boy' ? 'くん' : 'ちゃん'}</Text>
          <Text fontSize={11} fontWeight={"bold"}>{child.age}歳　{child.gender == 'boy' ? '男の子' : '女の子'}</Text>
        </VStack>
      ) : (
        <VStack flex={1} align={'start'} spacing={1}>
          <HStack>
            <Text fontSize={14} fontWeight={"bold"}>{child.name}{child.gender == 'boy' ? 'くん' : 'ちゃん'}</Text>
            <Text fontSize={11} fontWeight={"bold"}>{child.age}歳　{child.gender == 'boy' ? '男の子' : '女の子'}</Text>
          </HStack>
          <CrownText bg={Color.gold} color={Color.white} small bold py={1.5} px={2}>
            {child.tci.title + child.tci.conjunction + child.mi.title}タイプ
          </CrownText>
        </VStack>
      )}
      {action}
    </HStack>
  )
}

