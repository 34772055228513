import {Box} from "@chakra-ui/react";
import {PlanSummaryItem} from "./PlanSummaryItem";
import {ShippingPlan} from "../../../model/shippingPlan";

type Props = {
  shippingPlans: ShippingPlan[]
}

export const PlanSummary = (props: Props) => {
  const { shippingPlans } = props

  return (
    <Box
      w={'100%'}
      px={'50%'}
      overflowX={'scroll'}
      display={'flex'}
      gap={4}
      style={{
        perspective: '100px',
        perspectiveOrigin: 'top',
        scrollSnapType: 'x mandatory',
        scrollbarWidth: 'none'
      }}
    >
      {
        shippingPlans.map((plan) => (
          <PlanSummaryItem key={plan.time} shippingPlan={plan} />
        ))
      }
    </Box>
  )
}